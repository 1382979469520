// @flow

import React from "react";
import {
    InspectionPointLayout,
    Layout,
    InfringementDetails,
    withPrivateRoute,
} from "@containers";

/**
 * Reactie
 */
const Tekorten = (props: *) => (
    <Layout {...props}>
        <InspectionPointLayout>
            <InfringementDetails
                infringementId={props.infringementId}
                inspectionPointId={props.inspectionPointId}
                title="Aandachtspunt"
            />
        </InspectionPointLayout>
    </Layout>
);

export default withPrivateRoute(
    Tekorten,
    "/inspectiepunt/:inspectionPointId/aandachtspunten/:infringementId",
);
